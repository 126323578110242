import React from 'react'

const AuthenticateImage = () => {
  return (
    <>
        <div class="col-sm-6 px-0 d-none d-sm-block">
    <div class="rounded-3 h-100">
        <img src="./img/user/login/Frame 1686554390.png"
            alt="Login image"
            class="w-100 p-3 login-image-responsive"
            style={{ objectPosition: 'left', borderRadius:'30px'}} />
    </div>
</div>

  </>
  )
}

export default AuthenticateImage